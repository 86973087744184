/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { ReactComponent as DriveIcon } from '../../assets/drive_icon.svg';

/** Import helpers section **/
import './ClientsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
import ActionsMenu from '../../components/ActionsMenu/ActionsMenu';



const ClientsView = ({
  loading,
  clients,
  onFilterBy,
  onAddClient,
  onEditClient,
  onClientWorkOrders,
  onClientDocs,
  onDeleteClient,
  onAddWorkOrder,
  onFolderDrive,
}) => {

  const FolderDriveMenuOption = () => (
    <DriveIcon
      className="g-row-action-button complementary"
    />
  )

  const AddWoMenuOption = () => (
    <BusinessCenterIcon
      className="g-row-action-button"
    />
  )

  const EditClientMenuOption = () => (
    <EditIcon
      className="g-row-action-button"
    />
  )

  const InfoWoMenuOption = () => (
    <InfoIcon
      className="g-row-action-button complementary"
    />
  )

  const DocsWoMenuOption = () => (
    <FileCopyIcon
      className="g-row-action-button complementary"
    />
  )

  const DeleteMenuOption = () => (
    <DeleteIcon
      className="g-row-action-button delete"
    />
  )

  const ActionsCell = ({ row }) => {
    const actions = [
      { icon: <FolderDriveMenuOption row={row} />, title: 'Ir a la carpeta Google Drive', callback: () => onFolderDrive(row) },
      { icon: <EditClientMenuOption row={row} />, title: 'Editar', callback: () => onEditClient(row) },
      { icon: <InfoWoMenuOption row={row} />, title: 'Ordenes del cliente', callback: () => onClientWorkOrders(row) },
      { icon: <AddWoMenuOption row={row} />, title: 'Agregar orden de trabajo', callback: () => onAddWorkOrder(row) },
      { icon: <DocsWoMenuOption row={row} />, title: 'Documentos', callback: () => onClientDocs(row) },
      { icon: <DeleteMenuOption row={row} />, title: 'Borrar', callback: () => onDeleteClient(row) }
    ]
    return (
      <div className="g-actions-cell">
        <ActionsMenu actions={actions}
        />

      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Número de cliente',
        selector: 'id',
        sortable: true,
        center: true,
        width: '80px'
      },
      {
        name: 'Cliente',
        selector: (row) => row?.title,
        sortable: true,
        center: true,
        wrap: true,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Compañía',
        selector: (row) => row?.company?.name,
        sortable: true,
        center: true,
        wrap: true,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Actividad / Giro',
        selector: (row) => row?.activity?.name,
        sortable: true,
        center: true,
        wrap: true,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Riesgo',
        selector: (row) => row?.risk?.title,
        sortable: true,
        center: true,
        width: '50px'
      },
      {
        name: 'Contacto',
        selector: 'contact_name',
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true,
        wrap: true,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Teléfono',
        selector: 'phone',
        sortable: true,
        center: true
      },
      {
        name: 'Celular',
        selector: 'cell_phone',
        sortable: true,
        center: true
      },
      {
        name: 'Municipio',
        selector: 'municipality',
        sortable: true,
        center: true
      },
      {
        name: 'Acciones',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        width: '240px',
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Clientes" />
      <Paper className="g-page-header clients_header">
        <div className="clients_header_filterby">
          <FilterBy onChange={onFilterBy} />
        </div>
        <Button onClick={onAddClient} startIcon={<AddCircleIcon />}>
          Agregar nuevo cliente
        </Button>
      </Paper>

      <DataTable data={clients} columns={tableColumns} loading={loading} showDownload={true} />
    </>
  );
};

export default ClientsView;
