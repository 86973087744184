/** Import react/libraries section **/
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

/** Import component section **/
/** Import helpers section **/
/** Import resources section **/
import './ActionsMenu.scss';
import Menu from '@material-ui/core/Menu';
import UndoIcon from '@material-ui/icons/Undo';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import BlockIcon from '@material-ui/icons/Block';
import MenuItem from '@material-ui/core/MenuItem';
import ApproveIcon from '@material-ui/icons/Check';
import ArchiveIcon from '@material-ui/icons/Archive';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListIcon from '@material-ui/icons/List';

export const ActionsMenu = ({ actions }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            {actions.length > 0 && (
                <>
                    <div className="actionMenu_button">
                        {actions.length > 1 ? (
                            <div className="actionMenu_button_dropdown">
                                <ListIcon
                                    data-cy="cy-menu-options"
                                    onClick={handleClick}
                                />
                            </div>
                        ) : null}
                    </div>

                    {actions.length > 1 && (
                        <Menu
                            id="actions-menu"
                            open={open}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            transformOrigin={{
                                vertical: -40,
                                horizontal: -40
                            }}
                        >
                            {actions.map((action, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={action.callback}
                                    className="actionMenu_item"
                                >
                                    {action.icon} {'  '} {action.title}
                                </MenuItem>
                            )
                            )}
                        </Menu>
                    )}
                </>
            )}
        </>
    );
};

const actionsDef = [
    { action: 'save', icon: SaveIcon },
    { action: 'print', icon: PrintIcon },
    { action: 'approve', icon: ApproveIcon },
    { action: 'disapprove', icon: UndoIcon },
    { action: 'delivered', icon: LocalShippingIcon },
    { action: 'closed', icon: ArchiveIcon },
    { action: 'reject', icon: BlockIcon },
    { action: 'menu', icon: ListIcon }
];

const actionsArr = () => {
    return actionsDef.map(({ action }) => action);
};

ActionsMenu.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.oneOf(actionsArr()).isRequired,
            title: PropTypes.string.isRequired,
            icon: PropTypes.any,
            callback: PropTypes.func.isRequired
        }).isRequired
    )
};

export default ActionsMenu;
