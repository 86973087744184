/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getClientsService,
  getClientByIdService,
  deleteClientService,
  changeActiveService,
  updateClientService
} from '../../api/services/clients-services';

import { getWorkOrderStatusService } from '../../api/services/work-orders-services';

import { getResponsibleListService } from '../../api/services/users-services';

/** Import resources section **/
import { CLIENT_DOCUMENTS_ROUTE } from '../../config/URL_ROUTES';

/** Import helpers section **/
import './ClientsStyles.scss';

/** Import component section **/
import Modal from '../../components/Modal';
import ClientModal from './ClientModal';
import ClientOrdersModal from './ClientOrdersModal';
import ClientsView from './ClientsView';
import WorkOrderModal from '../WorkOrders/WorkOrderModal';
import MUITextField from '../../components/inputs/MUITextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const ClientModel = {
  id: null,
  company_id: null,
  risk_id: null,
  contact_name: '',
  phone: '',
  email: '',
  cell_phone: '',
  state_id: null,
  municipality: '',
  city: '',
  ext_number: '',
  reference: '',
  int_number: '',
  neighborhood: '',
  postal_code: '',
  area_square_meters: '',
  structure_square_meters: '',
  employees_number: '',
  street: '',
  drive_client_folder_url: ''
};

const WorkOrderModel = {
  id: null,
  proposal: '',
  work_order_status_id: 1,
  order_num: null,
  comments: '',
  client_id: null
};

const ClientsComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [workOrder, setWorkOrder] = useState(WorkOrderModel);
  const [status, setStatus] = useState([]);

  const history = useHistory();

  const getResponsibles = useCallback(() => {
    getResponsibleListService()
      .then((response) => {
        if (response?.responsibles) setResponsibles(response?.responsibles);
      })
      .catch((error) => {
        toast.warning('Error al conseguir los responsables');
      });
  }, []);

  const getClients = useCallback(() => {
    setLoading(true);
    getClientsService()
      .then((response) => {
        setClients(response?.clients);
        setOriginalData(response?.clients);
      })
      .catch((error) => {
        console.error(
          'ClientsComponent - Error requesting clients list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getClients();
    getResponsibles();
  }, [getClients, getResponsibles]);

  const handleOnUpdateList = (selectedClient) => {
    getClients();
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal
        ?.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      filteredItems = originalData.filter((item) => {
        const field0 = item?.title
          ?.toString()
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '');
        const field1 = item?.company?.name
          ?.toString()
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '');
        const field2 = item?.activity?.name?.toString().toLowerCase();
        const field3 = item?.contact_name?.toString().toLowerCase();
        const field4 = item?.email?.toString().toLowerCase();
        const field5 = item?.phone?.toString().toLowerCase();
        const field6 = item?.cell_phone?.toString().toLowerCase();

        return (
          field0.match(filteringText) ||
          field1.match(filteringText) ||
          field2.match(filteringText) ||
          field3.match(filteringText) ||
          field4.match(filteringText) ||
          field5.match(filteringText) ||
          (field6.match(filteringText) && true)
        );
      });
    }

    setClients(filteredItems);
  };

  const handleOnAddClient = () => {
    setSelectedClient(Object.assign({}, ClientModel));
    setModalMode('client');
  };

  const getClientById = (id, mode) => {
    getClientByIdService({ id })
      .then((response) => {
        setSelectedClient(response?.client);
        setModalMode(mode);
      })
      .catch((error) => {
        console.error('ClientsComp - Error fetching user data: ', error);
      });
  };

  const handleOnEditClient = (row) => {
    getClientById(row.id, 'client');
  };

  const handleOnModalClose = () => {
    setSelectedClient(null);
    setModalMode(null);
  };

  const handleOnDeleteClient = (row) => {
    setSelectedClient(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteClientService({ id: selectedClient.id })
      .then(() => {
        getClients();
        toast.success('Cliente borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el cliente.');
      })
      .finally(() => {
        setSelectedClient(null);
        setModalMode(null);
      });
  };

  const handleOnActiveChange = (row) => {
    setSelectedClient(row);
    setModalMode('active');
  };

  const handleOnFolderDrive = (row) => {
    setSelectedClient(row);
    setModalMode('folder-drive');
  };

  const handleOnActiveConfirm = () => {
    changeActiveService({ id: selectedClient.id })
      .then(() => {
        getClients();
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      })
      .finally(() => {
        setSelectedClient(null);
        setModalMode(null);
      });
  };

  const handleOnClientWorkOrders = (row) => {
    setSelectedClient(row);
    setModalMode('work-orders-info');
  };

  const handleOnClientDocs = (row) => {
    history.push({
      pathname: CLIENT_DOCUMENTS_ROUTE + '/' + row?.id,
      state: {
        from: 'clients'
      }
    });
  };

  const getWorkOrderStatus = useCallback(() => {
    getWorkOrderStatusService()
      .then((response) => {
        if (response?.status) setStatus(response?.status);
      })
      .catch((error) => {
        console.error(
          'WorkOrders Component - Error requesting clients list: ',
          error
        );
      });
  }, []);

  const handleOnAddWorkOrder = (row) => {
    console.log(row)
    if (row.url !== null) {
      let property = 'client_id';
      setSelectedClient(row?.id);
      setModalMode('work-order');
      setWorkOrder((prevState) => ({
        ...prevState,
        [property]: row?.id
      }));

      getWorkOrderStatus();
    } else {
      toast.warning(
        'Favor de registrar el folder de Drive. Intentelo de nuevo.'
      );
    }
  };

  const onInputChange = (data) => {
    const { name, value } = data;
    setSelectedClient((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnDriveConfirm = () => {
    updateClientService({
      id: selectedClient.id,
      drive_client_folder_url: selectedClient.drive_client_folder_url
    })
      .then(() => {
        getClients();
        toast.success(
          'Direccion a drive del cliente ' +
          selectedClient.name +
          ' actualizada'
        );
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el cliente.');
      })
      .finally(() => {
        setSelectedClient(null);
        setModalMode(null);
      });
  };

  const driveRedirect = (url) => {
    window.open(url, '_blank');
  };


  return (
    <>
      <ClientsView
        loading={loading}
        clients={clients}
        onFilterBy={handleOnFilterBy}
        onAddClient={handleOnAddClient}
        onEditClient={handleOnEditClient}
        onClientWorkOrders={handleOnClientWorkOrders}
        onClientDocs={handleOnClientDocs}
        onDeleteClient={handleOnDeleteClient}
        onActiveClient={handleOnActiveChange}
        onAddWorkOrder={handleOnAddWorkOrder}
        onFolderDrive={handleOnFolderDrive}
      />
      {selectedClient && modalMode === 'work-order' && (
        <WorkOrderModal
          workOrderData={workOrder}
          clientId={selectedClient}
          clients={clients}
          responsibles={responsibles}
          status={status}
          onUpdateList={handleOnUpdateList}
          onClose={handleOnModalClose}
        // redirect={true}
        />
      )}
      {selectedClient && modalMode === 'client' && (
        <ClientModal
          clientData={selectedClient}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedClient && modalMode === 'work-orders-info' && (
        <ClientOrdersModal
          clientData={selectedClient}
          onClose={handleOnModalClose}
        />
      )}
      {selectedClient && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar cliente"
        >
          {'¿Estas seguro que quieres borrar el cliente ' +
            selectedClient.title +
            '?'}
        </Modal>
      )}

      {selectedClient && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            selectedClient?.active === 0
              ? 'Activar cliente?'
              : 'Desactivar cliente'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedClient?.active === 0 ? 'Activar' : 'Desactivar') +
            ' el cliente ' +
            selectedClient.title +
            '?'}
        </Modal>
      )}
      {selectedClient && modalMode === 'folder-drive' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDriveConfirm}
          title={'Enlaces de ' + selectedClient.name + ' a Drive'}
          confirmText="Actualizar"
        >
          <div className="clients_drive_link">
            <MUITextField
              id="drive_client_folder_url"
              name="drive_client_folder_url"
              label="Actualizar enlace al folder del cliente"
              value={selectedClient?.drive_client_folder_url}
              onChange={onInputChange}
            />
          </div>
          {!!selectedClient?.drive_client_folder_url && (
            <>
              <div className="clients_text_redirect">
                Ir folder del cliente a Google Drive
              </div>
              <p className="clients_drive_link_ref">
                <ArrowForwardIcon
                  className="g-row-action-button check"
                  onClick={() =>
                    driveRedirect(selectedClient?.drive_client_folder_url)
                  }
                />

                {selectedClient?.drive_client_folder_url}
              </p>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default ClientsComponent;
