/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import { WORK_ORDERS_ROUTE } from '../../config/URL_ROUTES';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

/** Import syles section **/
import './ClientDocumentsRequieredStyles.scss';

/** Import component section **/
import DataTable from '../../components/DataTable/DataTableComponent';
import PageTitle from '../../components/PageTitle/PageTitleComponent';
import FilterBy from '../../components/FilterBy/FilterBy';
import MUICheckBox from '../../components/inputs/MUICheckBox';
import { ReactComponent as DriveIcon } from '../../assets/drive_icon.svg';
import { Tooltip } from '@material-ui/core';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ModalComponent from '../../components/Modal/ModalComponent';

const ClientDocumentsRequieredView = ({
  title,
  documents,
  loading,
  onFilterBy,
  onAddRequiredDocs,
  onSelectedChange,
  onEditComments,
  onEditGoogleDriveReference,
  onManageStatus
}) => {

  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        {!!row?.client_document_id && (
          <>
            <Tooltip
              title="Editar enlace de Google Drive"
              aria-label="Editar enlace de Google Drive"
            >
              <DriveIcon
                className="g-row-action-button complementary"
                onClick={() => onEditGoogleDriveReference(row)}
              />
            </Tooltip>
            <Tooltip
              title="Editar comentarios del documento"
              aria-label="Editar comentarios del documento"
            >
              <ModeCommentIcon
                className="g-row-action-button"
                onClick={() => onEditComments(row)}
              />
            </Tooltip>
            <Tooltip
              title="Editar estatus del documento"
              aria-label="Editar estatus del documento"
            >
              <CheckCircleIcon
                className="g-row-action-button secondary"
                onClick={() => onManageStatus(row)}
              />
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'Documento',
      selector: (row) => row?.document_type?.name,
      sortable: true,
      wrap: true
    },
    {
      name: 'Descripción',
      selector: (row) => row?.document_type?.description,
      sortable: true,
      center: true,
      grow: 1.5
    },
    {
      name: 'Status',
      selector: (row) => row?.require_doc_status_title,
      sortable: true,
      center: true
    },
    {
      name: 'Comentarios',
      selector: (row) => row?.comments,
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      // right: true
    }
  ];

  return (
    <>
      <PageTitle title={title} backUrl={WORK_ORDERS_ROUTE} />
      <Paper className="g-page-header woServiceDocs_header">
        <div className="serviceDocs_header_filters">
          <FilterBy onChange={onFilterBy} />
        </div>
        <div className="clientDocumentRequireds_addButton">
          <Button onClick={onAddRequiredDocs} startIcon={<SaveIcon />}>
            Actualizar documentos requeridos
          </Button>
        </div>
      </Paper>
      <DataTable
        data={documents}
        columns={tableColumns}
        loading={loading}
        selectableRows={true}
        selectableRowsComponent={MUICheckBox}
        selectableRowsNoSelectAll={false}
        selectableRowsHighlight={true}
        onSelectedRowsChange={onSelectedChange}
        selectableRowSelected={(row) => row.added}
      />
    </>
  );
};

export default ClientDocumentsRequieredView;
